.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.gap-12{
  gap: 12px;
}
.m-12{
  margin: 12px;
}
.mt-12{
  margin-top: 12px !important;
}
table th{
  font-weight: 700 !important;
}
table td, table th{
  padding: 4px !important;
}
.card-image-text{
  margin: 8px 0;
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  gap: 12px;
  border: 1px solid #ccc;
}
.card-image-text-icon{
  margin: 8px 0;
  display: grid;
  grid-template-columns: 80px 1fr 30px;
  align-items: center;
  gap: 12px;
  border: 1px solid #ccc;
}
.card-text-icon{
  margin: 8px;
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  gap: 12px;
  border: 1px solid #ccc;
}
.product-docs{
  border: 1px solid #ccc;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 12px;
}
.show-print{
  display: none !important;
}
